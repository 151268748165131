
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ImageItem } from '@/interfaces/ImageItem.interface'
import ImagePickerCarousel from '@/components/ImagePickerCarousel/ImagePickerCarousel.vue'
import ImagePickerGrid from '@/components/ImagePickerGrid/ImagePickerGrid.vue'

/**
 * Zeigt eine Bilderauswahl an, je nach Viewport als Carousel oder Grid.
 */
@Component({
  components: {
    ImagePickerCarousel,
    ImagePickerGrid
  }
})
export default class ImagePicker extends Vue {
  /**
   * v-model Binding; enthält den `value` des ausgewählten [[ImageItem]].
   */
  @Prop({
    type: String,
    required: false
  })
  public value?: string

  /**
   * Liste von Bildern, die zur Auswahl stehen.
   */
  @Prop({
    type: Array,
    required: true,
    validator: items =>
      !(items as Partial<ImageItem>[]).find(
        item =>
          typeof item.src !== 'string' ||
          typeof item.label !== 'string' ||
          typeof item.value !== 'string'
      )
  })
  public items!: ImageItem[]

  /**
   * Sprachkey, der im Titel angezeigt werden soll. Der Titel wird ausgeblendet,
   * wenn die Eigenschaft nicht angegeben wird.
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public langTitle!: string

  /**
   * Sprachkey für das Label des Bestätigungsbuttons. Der Button wird
   * ausgeblendet, wenn die Eigenschaft nicht angegeben wird.
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public langConfirm!: string

  /**
   * Sprachkey für das Label des Zurückbuttons. Der Button wird
   * ausgeblendet, wenn die Eigenschaft nicht angegeben wird.
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public langBack!: string

  /**
   * Zeigt an, ob die Auswahl möglich ist.
   */
  @Prop({
    type: Boolean
  })
  public disabled = false

  /**
   * Gibt an, ob die Carousel-Ansicht statt die Grid-Ansicht verwendet werden
   * soll. Basiert auf dem Breakpoint des Viewports.
   *
   * @returns true/false, je nachdem, ob das Carousel aktiv sein soll.
   */
  public get carousel(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  /**
   * Binding des v-model [[this.value]]. Wird für die Unterkomponenten als
   * v-model verwendet.
   *
   * @returns Wert von [[this.value]] oder Fallback ('').
   */
  public get selectedItemValue(): string {
    return this.value || ''
  }

  /**
   * Binding des v-model [[this.value]]. Wird für die Unterkomponenten als
   * v-model verwendet und reicht das input-Event weiter.
   *
   * @param value - Neuer Wert.
   */
  public set selectedItemValue(value: string) {
    if (this.selectedItemValue === value) {
      this.$emit('input', undefined)
    } else {
      this.$emit('input', value)
    }
  }

  /**
   * Event-Handler für den Klick auf den Bestätigungsbutton. Feuert ein
   * "confirm"-Event.
   *
   * @param $event - Click-Event.
   */
  public onConfirmClick($event: Event): void {
    this.$emit('confirm', $event)
  }

  /**
   * Event-Handler für den Klick auf den Zurückbutton. Feuert ein
   * "back"-Event.
   */
  public onBackClick(): void {
    this.$emit('back')
  }
}
