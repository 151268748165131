
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as countries from 'i18n-iso-countries'
import CountryFlag from '@/components/CountryFlag/CountryFlag.vue'
import CountryItem from '@/components/CountryPicker/CountryPicker.schema'

/**
 * Select zur Auswahl eines Landes.
 */
@Component({
  components: {
    CountryFlag
  }
})
export default class CountryPicker extends Vue {
  /**
   * Sprache, in der die Ländernamen erscheinen sollen.
   */
  @Prop({ type: String, required: true })
  public language!: string

  /**
   * Ausgewähltes Land als Kürzel
   */
  @Prop({ type: String, required: false })
  public value!: string

  /**
   * Anzeigename im Formular
   */
  @Prop({ type: String, required: false, default: '' })
  public label!: string

  /**
   * Regeln, die beim Ausfüllen beachtet werden.
   */
  @Prop({ type: Array, required: false })
  public rules?: unknown[]

  /**
   * Erzeugt ein Array aller Länder.
   * Wird beim Ändern der Sprache aktualisiert.
   *
   * @returns Array mit Ländern
   */
  @Watch('language')
  public get countries(): CountryItem[] {
    let register
    try {
      register = require(`i18n-iso-countries/langs/${this.language}.json`)
    } catch (e) {
      console.error(
        `Not able to load country names with locale "${this.language}".
        Using "en" instead.`
      )
      register = require(`i18n-iso-countries/langs/en.json`)
    }

    countries.registerLocale(register)

    const data: CountryItem[] = Object.entries(
      countries.getNames(this.language)
    )
      .map(entry => ({
        name: entry[1],
        value: entry[0]
      }))
      .sort((a, b) => a.name.localeCompare(b.name))

    return data
  }
}
