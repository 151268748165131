
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator'
import Dark from '@/mixins/dark'
import { getApp } from '@/helpers/feathers'
import { ImageItem } from '@/interfaces/ImageItem.interface'
import ImagePicker from '@/components/ImagePicker/ImagePicker.vue'
import { GenericErrorData } from '@/interfaces/GenericErrorData.interface'
import ErrorableCard from '@/components/ErrorableCard/ErrorableCard.vue'
import CountryPicker from '@/components/CountryPicker/CountryPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import validator from 'validator'

/**
 * Interface für die Besuchsgründe
 */
interface VisitReasons {
  /**
   * Zugehöriges Bild des Besuchsgrund
   */
  imageFilename: string
  /**
   * Die Sprache des Besuchsgrunds
   */
  language: string
  /**
   * Sortierreihenfolge
   */
  sort: number
  /**
   * Der Besuchsgrund
   */
  visitReason: string
  /**
   * Das Werk, in dem der Besuchsgrund gilt.
   *
   * Ist nur vorhanden, wenn in der Config die Option
   * [externalsFactorySplitChar] aktiviert ist.
   */
  factory?: string
  /**
   * Der Name des Besuchsgrunds.
   *
   * Ist nur vorhanden, wenn in der Config die Option
   * [externalsFactorySplitChar] aktiviert ist.
   */
  factoryVisitReason?: string
}

/**
 * Interface für die DSGVO
 */
interface DSGVO {
  /**
   * Sprache des DSGVO-Textes
   */
  language: string
  /**
   * Inhalt des DSGVO-Textes
   */
  text: string
  /**
   * Bestätigungstext
   */
  confirm: string
}

/**
 * Interface für alle Informationen, die ein externer Mitarbeiter eingeben kann
 */
interface ExternalInfo {
  /**
   * Vorname des Fremdmitarbeiters
   */
  firstName?: string

  /**
   * Nachname des Fremdmitarbeiters
   */
  lastName?: string

  /**
   * Firma des Fremdmitarbeiters
   */
  company?: string

  /**
   * Herkunftsland.
   * Wird nur abgefragt, wenn die ID.Prove-Prüfung aktiv ist.
   */
  country?: string

  /**
   * Besuchsgrund des Fremdmitarbeiters
   */
  visitReason?: string

  /**
   * Das gewählte Werk.
   */
  factory?: string
}

/**
 *
 */
interface AdditionalFieldData {
  [key: string]: string | number
}

/**
 * Verschiedene Seiten der Login Maske mit unterschiedlichen Funktionalitäten
 */
enum Page {
  /**
   * Seite mit dem DSGVO-Text
   */
  DSGVO = 1,
  /**
   * Seite mit den Standardinformationen
   */
  DefaultInfo = 2,
  /**
   * Seite mit den Besuchsgründen im ImagePicker
   */
  VisitReasonPicker = 3,
  /**
   * Seite mit den zusätzlichen Informationen
   */
  AdditionalInfo = 4
}

/**
 * Konzept für einen Fremdmitarbeiter Login
 */
@Component({
  components: {
    ImagePicker,
    ErrorableCard,
    CountryPicker,
    DatePicker
  }
})
export default class ExternalLogin extends Mixins(Dark) {
  /**
   * Aktiviert die Seite mit dem DSGVO-Text
   */
  @Prop({ type: Boolean, required: false, default: false })
  public dsgvoPage!: boolean

  /**
   * Alle Informationen, die ein externer Mitarbeiter eingeben kann
   */
  public externalInfo: ExternalInfo = {}

  /**
   * Die Besuchsgründe in allen Sprachen
   */
  public visitReasons: VisitReasons[] = []

  /**
   * DSGVO-Info in allen Sprachen
   */
  public dsgvo: DSGVO[] = []

  /**
   * Die wählbaren Firmen
   */
  public companies: string[] = []

  /**
   * Suchstring des Firma-Eingabefeldes
   */
  public searchedCompany = ''

  /**
   * Objekt, dass den Anzeige-Status der verschiedenen Datepicker beinhaltet
   */
  public showPicker = {}

  /**
   * Seite der externen Login-Maske
   */
  public page: Page = Page.DSGVO

  /**
   * Gültigkeit der Standardinformationen
   */
  public validDefaultInfo = false

  /**
   * Gültigkeit der zusätzlichen Informationen
   */
  public validAdditionalInfo = false

  /**
   * Gibt an, welche zusätzlichen Felder zur Abfrage des Fremdmitarbeiters
   * angezeigt werden sollen
   */
  public additionalFields: AdditionalFieldData[] = []

  /**
   * Die Daten der zusätzlichen Felder, zur Weitergabe an den Server
   */
  public additionalFieldData: AdditionalFieldData = {}

  /**
   * Zusätzliche Felder Typen und deren Informationen zum dynamischen
   * Zusammenbau der Seite
   */
  public additionalFieldTypes = {
    string: [
      {
        name: 'Employees._Ausweisnr',
        lang: 'externalLogin.field.id'
      },
      {
        name: 'Employees._Ausbildung',
        lang: 'externalLogin.field.training'
      },
      {
        name: 'Employees.titleName',
        lang: 'externalLogin.field.titel'
      },
      {
        name: 'Employees._prefixName',
        lang: 'externalLogin.field.prefixName'
      },
      {
        name: 'Employees._suffixName',
        lang: 'externalLogin.field.suffixName'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.street',
        lang: 'externalLogin.field.street'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.zipcode',
        lang: 'externalLogin.field.zip'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.city',
        lang: 'externalLogin.field.city'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.working_time',
        lang: 'externalLogin.field.workingTime'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.working_tasks',
        lang: 'externalLogin.field.workingTasks'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.citizenship',
        lang: 'externalLogin.field.citizenship'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.health_insurance_name',
        lang: 'externalLogin.field.healthInsuranceName'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.health_insurance_zipcode',
        lang: 'externalLogin.field.healthInsuranceZip'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.health_insurance_city',
        lang: 'externalLogin.field.healthInsuranceCity'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.health_insurance_street',
        lang: 'externalLogin.field.healthInsuranceStreet'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.birthplace',
        lang: 'externalLogin.field.birthplace'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.letterSalutation',
        lang: 'externalLogin.field.letterSalutation'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.adressAddition',
        lang: 'externalLogin.field.adressAddition'
      },
      {
        name: 'Employees._Telefon',
        lang: 'externalLogin.field.phone'
      },
      {
        name: 'Employees._Fax',
        lang: 'externalLogin.field.fax'
      }
    ],
    date: [
      {
        name: 'tbl_HCM_Mitarbeiter.birthday',
        lang: 'externalLogin.field.birthdate'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.hire_date',
        lang: 'externalLogin.field.hireDate'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.leavingDate',
        lang: 'externalLogin.field.leavingDate'
      }
    ],
    mail: [
      {
        name: 'Employees.email',
        lang: 'externalLogin.field.email'
      }
    ],
    dropdown: [
      {
        name: 'Employees._Land',
        lang: 'externalLogin.field.country'
      },
      {
        name: 'Employees._salutation',
        lang: 'externalLogin.field.salutation'
      },
      {
        name: 'tbl_HCM_Mitarbeiter.gender',
        lang: 'externalLogin.field.gender'
      }
    ]
  }

  /**
   * Fehlerdaten. Der Fehler wird in der Maske angezeigt, wenn dieses Feld
   * gesetzt ist.
   */
  public error: GenericErrorData | null = null

  /**
   * Einstellungen, die aus dem Backend geladen werden, die die Darstellung
   * und das Verhalten des Fremdmitarbeiterlogins beeinflussen können. Werden
   * beim Laden durch die Daten des `login-Settings`-Services überschrieben.
   */
  public loginSettings = {
    externalsVisitreasonImages: false,
    askForAdditionalHcmInformation: false,
    idProve: {
      mode: 0
    },
    additionalFields: [],
    companyDropdownValue: 0
  }

  /**
   * Mapping von Feldern im Fehlerzustand zu Fehlermeldungen. Beispielsweise
   * kann "firstName" mit einer Meldung als Wert angegeben werden, damit im Feld
   * für "firstName" ein Fehler angezeigt wird.
   */
  public validationErrors: { [key: string]: string | string[] | null } = {}

  /**
   * Holt die Besuchsgründe/Firmen und DSGVO-Texte aus dem Backend und triggert
   * die Filter-Funktion
   */
  public async created(): Promise<void> {
    try {
      this.loginSettings = await (await getApp())
        .service('login-settings')
        .find()

      this.visitReasons = await (await getApp()).service('visit-reasons').find()

      if (this.loginSettings.additionalFields) {
        this.additionalFields = this.loginSettings.additionalFields
      }

      if (this.dsgvoPage) {
        this.dsgvo = await (await getApp()).service('dsgvo').find()
      }

      if (!this.dsgvoPage || !this.dsgvoLocal.text) {
        this.page++
      }
    } catch (e) {
      throw new Error(`A service is not availible: ${e.message}.`)
    }
  }

  /**
   * Trägt bei einem Input des Date-Pickers die Daten in das Objekt ein mit dem
   * entsprechenden Key
   *
   * @param value - Datum aus dem Date-Picker
   * @param key - Key in dem es gespeichert werden soll
   */
  public onDatePickerInput(value: string, key: string): void {
    this.additionalFieldData[key] = value
  }

  /**
   * Gibt die Werke zurück, wenn der Besuchsgrund nach Werken und
   * Besuchsgrudnamen getrennt wird.
   *
   * @returns - Werke der gewählten Sprache.
   */
  public get factoriesLocal(): string[] {
    const defaultFactoryName = this.$t(
      'externalLogin.factory.notSpecified'
    ).toString()

    const factories: string[] = this.visitReasons
      .filter(x => x.language === this.$i18n.locale)
      .map(r => r.factory || defaultFactoryName)

    const uniqueFactories = [...new Set(factories)].sort(
      (a: string, b: string) => {
        if (a === defaultFactoryName) {
          return -1
        }

        if (b === defaultFactoryName) {
          return 1
        }

        return a.localeCompare(b)
      }
    )

    return uniqueFactories
  }

  /**
   * Filtert die Besuchsgründe nach der ausgewählten Sprache
   *
   * @returns - Die nach Sprache gefilterten Besuchsgründe
   */
  public get visitReasonsLocal(): VisitReasons[] {
    const defaultFactoryName = this.$t(
      'externalLogin.factory.notSpecified'
    ).toString()

    const result = this.visitReasons.filter(
      x => x.language === this.$i18n.locale
    )

    // Werden Besuchsgrundbilder bei der Auswahl verwendet, wird nicht nach
    // Werken unterschieden.
    if (this.loginSettings.externalsVisitreasonImages) {
      return result
    }

    // Gibt es Werke, so wird nach diesen gefiltert.
    if (this.factoriesLocal.length > 1) {
      return result.filter(
        x => (x.factory || defaultFactoryName) === this.externalInfo.factory
      )
    }

    return result
  }

  /**
   * Array mit den aktiven Seiten des Logins, basierend auf verschiedenen props
   *
   * @returns - Die verschiedenen aktiven Seiten des Logins
   */
  public get activePages(): Page[] {
    return [
      ...(this.dsgvoPage ? [Page.DSGVO] : []),
      Page.DefaultInfo,
      ...(this.loginSettings.externalsVisitreasonImages
        ? [Page.VisitReasonPicker]
        : []),
      ...(this.loginSettings.askForAdditionalHcmInformation &&
      this.additionalFields.length > 0
        ? [Page.AdditionalInfo]
        : [])
    ]
  }

  /**
   * Baut aus den [[visitReasonsLocal]] ein Objekt mit den Besuchgründen,
   * welches der ImagePicker interpretieren kann
   *
   * @returns - Das fertige Objekt mit den Besuchsgründen für den ImagePicker
   */
  public get imagePickerObject(): ImageItem[] {
    const visitReasonImageItems: ImageItem[] = []

    this.visitReasonsLocal.forEach(function (part, index, theArray) {
      visitReasonImageItems.push({
        label: theArray[index]['visitReason'],
        src: window.location.origin + theArray[index]['imageFilename'],
        value: theArray[index]['visitReason']
      })
    })

    return visitReasonImageItems
  }

  /**
   * Filtert die Besuchsgründe nach der ausgewählten Sprache
   *
   * @returns - Die nach Sprache gefilterten Besuchsgründe
   */
  public get dsgvoLocal(): DSGVO {
    if (!this.dsgvoPage || this.dsgvo.length === 0) {
      return { language: '', text: '', confirm: '' }
    }

    const dsgvoAll = this.dsgvo.filter(x => x.language === this.$i18n.locale)

    const dsgvoFallback = this.dsgvo.filter(
      x => x.language === this.$i18n.fallbackLocale
    )

    return dsgvoAll[0] || dsgvoFallback[0]
  }

  /**
   * Sucht anhand eines Suchstrings eine oder mehrere Firmen
   * und schreibt die Ergebnisse nach [[companies]].
   *
   * @returns leeres Versprechen
   */
  @Watch('searchedCompany')
  public async getCompanies(): Promise<void> {
    // Workaround für <https://github.com/vuetifyjs/vuetify/issues/4679>.
    if (this.searchedCompany !== null) {
      this.externalInfo.company = this.searchedCompany
    }

    if (
      !this.searchedCompany ||
      this.searchedCompany.length < this.loginSettings.companyDropdownValue ||
      this.loginSettings.companyDropdownValue === 0
    ) {
      this.companies = []
      return
    }

    const result = await (await getApp()).service('external-companies').find({
      query: {
        $search: this.searchedCompany
      }
    })

    this.companies = result
  }

  /**
   * Setzt den Fremdmitarbeiter-Login sprachabhängig zurück, da sich bei einem
   * Sprachwechsel die Besuchsgründe ändern und ein anderer DSGVO-Text angezeigt
   * wird (falls in der Sprache vorhanden)
   */
  public reset(): void {
    this.page = Page.DSGVO
    delete this.externalInfo.visitReason
    delete this.externalInfo.factory
  }

  /**
   * Wird bei Auswahl eines Werks getriggert, um die Auswahl des Besuchgrundes
   * zurückzusetzen.
   */
  public resetReason(): void {
    delete this.externalInfo.visitReason
  }

  /**
   * Gibt an, ob momentan ein Loginvorgang ausgeführt wird. Dadurch werden bspw.
   * Buttons (temporär) deaktiviert.
   */
  public loginInProgress = false

  /**
   * Sendet das Fremdmitarbeiterformular an den Server und leitet bei
   * erfolgreicher Erstellung eines JWT an eplas10L weiter.
   */
  public async login(): Promise<void> {
    this.loginInProgress = true

    try {
      await (
        await getApp()
      ).authenticate({
        strategy: 'external',
        payload: {
          firstName: this.externalInfo.firstName,
          lastName: this.externalInfo.lastName,
          company: this.externalInfo.company,
          country: this.externalInfo.country,
          language: this.$i18n.locale,
          visitReason: this.externalInfo.visitReason,
          additionalFields: this.additionalFieldData
        }
      })

      window.location.href = window.location.origin + '/index.php'
    } catch (ex) {
      if (ex.data.errorCode > 0 || ex.data.errorCode === 0) {
        this.error = {}
        this.error.code = ex.data.errorCode

        if (ex.message) {
          this.error.replacements = { message: ex.message }
        }

        if (ex.data.validationErrors) {
          this.validationErrors = ex.data.validationErrors

          Object.keys(this.validationErrors).forEach(key => {
            const unwatch = this.$watch(
              this.watcherName(key),
              (newValue, oldValue) => {
                if (newValue !== oldValue) {
                  this.validationErrors[key] = null
                  unwatch()
                }
              }
            )
          })
        }

        if (ex.data.details) {
          this.error.replacements = { details: JSON.stringify(ex.data.details) }
        }
      }

      this.loginInProgress = false

      // Authentifizierungsstatus zurücksetzen, damit die rejected Promise nicht
      // beim nächsten Versuch wiederverwendet wird.
      ;(await getApp()).set('authentication', null)

      throw ex
    }
  }

  /**
   * Mappt den Namen eines Error-Feldes auf das entsprechende lokale Feld.
   *
   * @param key - Error-Feld.
   * @returns Pfad zum lokalen Feld.
   */
  public watcherName(key: string): string {
    if (key === 'company') {
      return 'searchedCompany'
    }
    return 'externalInfo.' + key
  }

  /**
   * Erhöht die Anzahl der aktuellen Seite einstellungsabhängig
   */
  public nextPage(): void {
    const curentIndex = this.activePages.indexOf(this.page)

    if (this.activePages[curentIndex + 1]) {
      this.page = this.activePages[curentIndex + 1]
      return
    }
    this.login()
  }

  /**
   * Sucht nach einem vorhandenen externen Mitarbeiter. Ist einer vorhanden
   * werden die `additionalFields` über den `external-employee-fields`-Service
   * geupdatet.
   */
  public async searchExternalEmployee(): Promise<void> {
    this.loginInProgress = true

    try {
      const additionalFieldsForEmployee = await (await getApp())
        .service('external-employee-fields')
        .get(0, {
          query: {
            firstName: this.externalInfo.firstName,
            lastName: this.externalInfo.lastName,
            company: this.externalInfo.company
          }
        })

      if (additionalFieldsForEmployee.length > 0) {
        const fields: AdditionalFieldData[] = []
        this.additionalFields.forEach((entry: AdditionalFieldData) => {
          if (additionalFieldsForEmployee.includes(entry.name)) {
            fields.push(entry)
          }
        })

        this.additionalFields = fields
        this.nextPage()
      } else {
        this.additionalFields = []
        this.nextPage()
      }
    } catch (err) {
      if (err.message === 'External employee not found.') {
        this.additionalFields = this.loginSettings.additionalFields
        this.nextPage()
      } else {
        this.error = {}
        this.error.code = err.data.errorCode
        this.error.replacements = { message: err.message }
      }
    }
    this.loginInProgress = false
  }

  /**
   * Verringert die Zahl der aktuellen Seite, um so
   * einstellungsabhängig eine Seite zurück zu gehen.
   */
  public lastPage(): void {
    const currentIndex = this.activePages.indexOf(this.page)

    this.page = this.activePages[currentIndex - 1]
  }

  /**
   * Setzt die Maske zurück und überspringt die DSGVO-Seite unter bestimmten
   * Umständen
   */
  @Watch('$i18n.locale')
  public languageChange(): void {
    this.reset()

    if (!this.dsgvoPage || !this.dsgvoLocal.text) {
      this.page++
    }
  }

  /**
   * Überprüfung ob ein Pflichtfeld Inhalt hat
   *
   * @returns Regeln für Pflichtfelder
   */
  public get requiredRule(): ((v: boolean | number) => string | true)[] {
    return [
      (v: boolean | number): true | string => {
        if (typeof v === 'number' && v === 0) {
          return true
        }

        return !!v || this.$t('externalLogin.rule.required').toString()
      }
    ]
  }

  /**
   * Überprüfung ob das Feld eine Zahl eine Nummer ist
   *
   * @returns Regeln numerische Felder
   */
  public get numberRule(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        !v ||
        Number.isInteger(Number(v)) ||
        this.$t('externalLogin.rule.number').toString()
    ]
  }

  /**
   * Überprüfen ob das Feld eine E-Mail beinhaltet
   *
   * @returns Regeln für E-Mail-Felder
   */
  public get emailRule(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        !v ||
        validator.isEmail(String(v)) ||
        this.$t('externalLogin.rule.email').toString()
    ]
  }

  /**
   * Überprüfen ob das Feld eine E-Mail beinhaltet unter dem Aspekt, dass das
   * Feld ein Pflichtfeld ist
   *
   * @returns Regel für E-Mail-Pflichtfelder
   */
  public get emailRuleRequired(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        (!!v && validator.isEmail(String(v))) ||
        this.$t('externalLogin.rule.email').toString()
    ]
  }

  /**
   * Prüft ob ein Fehler geworfen wurde und setzt die Maske entsprechend
   * zurück.
   */
  @Watch('error')
  public resetOnError(): void {
    if (this.error) {
      this.reset()
      this.page++
    }
  }
}
