
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ImageItem } from '@/interfaces/ImageItem.interface'
import ImageCard from '@/components/ImageCard/ImageCard.vue'

/**
 * Carousel-Ansicht des ImagePickers.
 */
@Component({
  components: {
    ImageCard
  }
})
export default class ImagePickerCarousel extends Vue {
  /**
   * v-model Binding; enthält den `value` des ausgewählten [[ImageItem]].
   */
  @Prop({
    type: String,
    required: false
  })
  public value?: string

  /**
   * Liste von Bildern, die zur Auswahl stehen.
   */
  @Prop({
    type: Array,
    required: true,
    validator: items =>
      !(items as Partial<ImageItem>[]).find(
        item =>
          typeof item.src !== 'string' ||
          typeof item.label !== 'string' ||
          typeof item.value !== 'string'
      )
  })
  public items!: ImageItem[]

  /**
   * Gibt an, ob die Pfeile zum Durchscrollen angezeigt werden.
   */
  @Prop({
    type: Boolean
  })
  public showArrows = false

  /**
   * Wählt den `value` eines Bildes ([[this.items]]) aus und feuert das input-
   * Event, um das gebundene v-model zu aktualisieren.
   *
   * @param value - `value` eines [[ImageItem]].
   */
  public select(value: string): void {
    this.$emit('input', value)
  }

  /**
   * Mappt den `value` eines [[ImageItem]] auf den Slide-Index des
   * `<v-carousel>`.
   *
   * @returns Slide-Index des `<v-carousel>`.
   */
  public get slide(): number {
    const item = this.items.find(item => item.value === this.value)

    if (!item) {
      this.slide = 0
      return 0
    }

    return this.items.indexOf(item)
  }

  /**
   * Aktualisiert das gebundene v-model via input-Event, wenn sich der
   * Slide-Index verändert.
   *
   * @param index - Neuer Index.
   */
  public set slide(index: number) {
    this.$emit('input', this.items[index]?.value)
  }

  /**
   * Setzt den Slide-Index zurück, wenn sich [[this.items]] ändert.
   */
  @Watch('items')
  public resetSlide(): void {
    this.slide = 0
  }
}
