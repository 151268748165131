
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Event-Sammlung für gesammelte Bindings an Unterkomponenten.
 */
interface Events {
  /**
   * Click-Event Handler.
   */
  click?: ($event: Event) => void
}

/**
 * Klickbare Bild-Karte mit Label. Der Standard-Slot ist das Label.
 */
@Component({})
export default class ImageCard extends Vue {
  /**
   * URL des Bildes, was als Hintergrund angezeigt werden soll (Pflichtangabe).
   */
  @Prop({
    type: String,
    required: true
  })
  public src!: string

  /**
   * Seitenverhältnis des Bildes (standardmäßig 1:1).
   */
  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  public aspectRatio!: number

  /**
   * Gibt an, ob das Bild statt abgeschnitten verkleinert werden soll
   * (resultiert in Rändern um das Bild, standardmäßig `false`).
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public contain!: boolean

  /**
   * Gibt an, ob die Karte ausgewählt ist. Dies hat innerhalb der Komponente
   * keine Auswirkung und ist eine rein optische Änderung.
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public selected!: boolean

  /**
   * Stellt die Karte semi-transparent dar.
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public faded!: boolean

  /**
   * Gibt an, ob das Label momentan ausgeklappt/erweitert dargestellt wird.
   */
  public labelExpanded = false

  /**
   * Gibt das Click-Event der `<v-card>` weiter (via emit).
   *
   * @returns Event-Handler, falls an die Komponente selbst ein Click-Event
   * gebunden wurde.
   */
  public get onClick(): (($event: Event) => void) | void {
    if (!this.$listeners.click) {
      return
    }

    return ($event: Event) => {
      this.$emit('click', $event)
    }
  }

  /**
   * Erzeugt ein Objekt mit allen dieser Komponente zugeordneten Events.
   *
   * @returns Event-Objekt.
   */
  public get events(): Events {
    const events: Events = {}

    if (this.onClick) {
      events.click = this.onClick
    }

    return events
  }

  /**
   * Liste der Klassen für das Label-Element.
   *
   * @returns Array mit CSS-Klassen.
   */
  public get labelClasses(): string[] {
    const classes = ['image-card__label']

    if (this.labelExpanded) {
      classes.push('image-card__label--expanded')
    }

    return classes
  }

  /**
   * Klappt das Label aus bzw. wieder ein.
   */
  public toggleLabel(): void {
    this.labelExpanded = !this.labelExpanded
    // Element blurren, damit das leichte orange Overlay nach dem Klick wieder
    // verschwindet.
    ;((this.$refs.expandButton as Vue).$el as HTMLElement).blur()
  }

  /**
   * Erzeugt eine Liste von CSS-Klassen, die für die <v-card> verwendet werden.
   *
   * @returns Liste von CSS-Klassen.
   */
  public get cardClasses(): string[] {
    const classes = []

    if (this.faded) {
      classes.push('image-card__card--faded')
    }

    return classes
  }
}
