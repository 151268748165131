
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ImageItem } from '@/interfaces/ImageItem.interface'
import ImageCard from '@/components/ImageCard/ImageCard.vue'

/**
 * Grid-Ansicht des ImagePickers.
 */
@Component({
  components: {
    ImageCard
  }
})
export default class ImagePickerGrid extends Vue {
  /**
   * v-model Binding; enthält den `value` des ausgewählten [[ImageItem]].
   */
  @Prop({
    type: String,
    required: false
  })
  public value?: string

  /**
   * Zeigt an, ob die Bilderauswahl gerade aktiv ist.
   */
  @Prop({
    type: Boolean
  })
  public selectable = true

  /**
   * Liste von Bildern, die zur Auswahl stehen.
   */
  @Prop({
    type: Array,
    required: true,
    validator: items =>
      !(items as Partial<ImageItem>[]).find(
        item =>
          typeof item.src !== 'string' ||
          typeof item.label !== 'string' ||
          typeof item.value !== 'string'
      )
  })
  public items!: ImageItem[]

  /**
   * Wählt den `value` eines Bildes ([[this.items]]) aus und feuert das input-
   * Event, um das gebundene v-model zu aktualisieren.
   *
   * @param value - `value` eines [[ImageItem]].
   */
  public select(value: string): void {
    if (this.selectable) {
      this.$emit('input', value)
    }
  }
}
