var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-slide-x-reverse-transition',{attrs:{"group":"","leave-absolute":""}},[(_vm.page === 1)?_c('errorable-card',{key:"dsgvoCard",attrs:{"flat":"","error":_vm.error}},[_c('v-card-text',[_c('v-form',[_c('v-alert',{staticClass:"external-login--alert-default",attrs:{"dense":"","icon":"mdi-alert-circle-outline","border":"left","colored-border":""}},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html:dsgvo",value:(_vm.dsgvoLocal.text),expression:"dsgvoLocal.text",arg:"dsgvo"}]})]),_c('v-card-actions',[_c('v-btn',{staticClass:"external-login--confirm-button",attrs:{"color":"primary"},on:{"click":_vm.nextPage}},[_c('span',{staticClass:"external-login--button-text"},[_vm._v(" "+_vm._s(_vm.dsgvoLocal.confirm)+" ")])])],1)],1)],1)],1):_vm._e(),(_vm.page === 2)?_c('errorable-card',{key:"defaultInfoCard",attrs:{"flat":"","error":_vm.error}},[_c('v-card-text',[_c('v-form',{model:{value:(_vm.validDefaultInfo),callback:function ($$v) {_vm.validDefaultInfo=$$v},expression:"validDefaultInfo"}},[_c('v-text-field',{attrs:{"label":_vm.$t('externalLogin.field.firstName') + ' *',"rules":_vm.requiredRule,"error":!!_vm.validationErrors.firstName,"error-messages":_vm.validationErrors.firstName
              ? _vm.$t('externalLogin.error.' + _vm.validationErrors.firstName)
              : ''},model:{value:(_vm.externalInfo.firstName),callback:function ($$v) {_vm.$set(_vm.externalInfo, "firstName", $$v)},expression:"externalInfo.firstName"}}),_c('v-text-field',{attrs:{"label":_vm.$t('externalLogin.field.lastName') + ' *',"rules":_vm.requiredRule,"error":!!_vm.validationErrors.lastName,"error-messages":_vm.validationErrors.lastName
              ? _vm.$t('externalLogin.error.' + _vm.validationErrors.lastName)
              : ''},model:{value:(_vm.externalInfo.lastName),callback:function ($$v) {_vm.$set(_vm.externalInfo, "lastName", $$v)},expression:"externalInfo.lastName"}}),_c('v-combobox',{attrs:{"items":_vm.companies,"search-input":_vm.searchedCompany,"flat":"","label":_vm.$t('externalLogin.field.company') + ' *',"rules":_vm.requiredRule,"error":!!_vm.validationErrors.company,"error-messages":_vm.validationErrors.company
              ? _vm.$t('externalLogin.error.' + _vm.validationErrors.company)
              : ''},on:{"update:searchInput":function($event){_vm.searchedCompany=$event},"update:search-input":function($event){_vm.searchedCompany=$event}},model:{value:(_vm.externalInfo.company),callback:function ($$v) {_vm.$set(_vm.externalInfo, "company", $$v)},expression:"externalInfo.company"}}),(_vm.loginSettings.idProve.mode > 0)?_c('country-picker',{attrs:{"language":_vm.$i18n.locale,"label":_vm.$t('externalLogin.field.country') + ' *',"rules":_vm.requiredRule},model:{value:(_vm.externalInfo.country),callback:function ($$v) {_vm.$set(_vm.externalInfo, "country", $$v)},expression:"externalInfo.country"}}):_vm._e(),(
            !_vm.loginSettings.externalsVisitreasonImages &&
            _vm.factoriesLocal.length > 1
          )?_c('v-autocomplete',{attrs:{"items":_vm.factoriesLocal,"label":_vm.$t('externalLogin.field.factory') + ' *',"flat":""},on:{"change":function($event){return _vm.resetReason()}},model:{value:(_vm.externalInfo.factory),callback:function ($$v) {_vm.$set(_vm.externalInfo, "factory", $$v)},expression:"externalInfo.factory"}}):_vm._e(),(!_vm.loginSettings.externalsVisitreasonImages)?_c('v-autocomplete',{attrs:{"items":_vm.visitReasonsLocal,"item-text":vr => vr.factoryVisitReason || vr.visitReason,"item-value":vr => vr.visitReason,"label":_vm.$t('externalLogin.field.visitReason') + ' *',"flat":"","rules":_vm.requiredRule,"error":_vm.validationErrors.visitReason,"error-messages":_vm.validationErrors.visitReason
              ? _vm.$t('externalLogin.error.' + _vm.validationErrors.visitReason)
              : ''},model:{value:(_vm.externalInfo.visitReason),callback:function ($$v) {_vm.$set(_vm.externalInfo, "visitReason", $$v)},expression:"externalInfo.visitReason"}}):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":!_vm.validDefaultInfo || _vm.loginInProgress,"loading":_vm.loginInProgress},on:{"click":_vm.searchExternalEmployee}},[_vm._v(" "+_vm._s(_vm.$t('externalLogin.buttonLabel.check'))+" ")])],1)],1)],1)],1):_vm._e(),(_vm.page === 3)?_c('image-picker',{key:"defaultInfoCard",attrs:{"flat":"","items":_vm.imagePickerObject,"disabled":_vm.loginInProgress,"lang-title":"externalLogin.field.visitReason","lang-confirm":"externalLogin.buttonLabel.proceed","lang-back":"externalLogin.buttonLabel.back"},on:{"confirm":_vm.nextPage,"back":_vm.lastPage},model:{value:(_vm.externalInfo.visitReason),callback:function ($$v) {_vm.$set(_vm.externalInfo, "visitReason", $$v)},expression:"externalInfo.visitReason"}}):_vm._e(),(_vm.page === 4)?_c('errorable-card',{key:"additionalInfoCard",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('externalLogin.title.additionalInfo'))+" ")]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.validAdditionalInfo),callback:function ($$v) {_vm.validAdditionalInfo=$$v},expression:"validAdditionalInfo"}},[_vm._l((_vm.additionalFields),function(entry){return [(
              _vm.additionalFieldTypes.string.find(x => x.name === entry.name)
            )?_c('v-text-field',{key:entry.name,attrs:{"label":_vm.$t(
                _vm.additionalFieldTypes.string.find(x => x.name === entry.name)
                  .lang
              ) + (entry.required ? ' *' : ''),"type":"text","rules":entry.required ? _vm.requiredRule : []},model:{value:(_vm.additionalFieldData[entry.name]),callback:function ($$v) {_vm.$set(_vm.additionalFieldData, entry.name, $$v)},expression:"additionalFieldData[entry.name]"}}):_vm._e(),(_vm.additionalFieldTypes.mail.find(x => x.name === entry.name))?_c('v-text-field',{key:entry.name,attrs:{"label":_vm.$t(
                _vm.additionalFieldTypes.mail.find(x => x.name === entry.name)
                  .lang
              ) + (entry.required ? ' *' : ''),"type":"text","rules":entry.required ? _vm.emailRuleRequired : _vm.emailRule},model:{value:(_vm.additionalFieldData[entry.name]),callback:function ($$v) {_vm.$set(_vm.additionalFieldData, entry.name, $$v)},expression:"additionalFieldData[entry.name]"}}):_vm._e(),(_vm.additionalFieldTypes.date.find(x => x.name === entry.name))?_c('date-picker',{key:entry.name,attrs:{"key-name":entry.name,"label":_vm.$t(
                _vm.additionalFieldTypes.date.find(x => x.name === entry.name)
                  .lang
              ),"rule":entry.required ? _vm.requiredRule : [],"required":entry.required},on:{"sendDate":_vm.onDatePickerInput},model:{value:(_vm.additionalFieldData[entry.name]),callback:function ($$v) {_vm.$set(_vm.additionalFieldData, entry.name, $$v)},expression:"additionalFieldData[entry.name]"}}):_vm._e(),(
              entry.name === 'Employees._Land' &&
              _vm.loginSettings.idProve.mode === 0
            )?_c('country-picker',{key:entry.name,attrs:{"language":_vm.$i18n.locale,"label":_vm.$t('externalLogin.field.country') + (entry.required ? ' *' : ''),"rules":entry.required ? _vm.requiredRule : []},model:{value:(_vm.additionalFieldData[entry.name]),callback:function ($$v) {_vm.$set(_vm.additionalFieldData, entry.name, $$v)},expression:"additionalFieldData[entry.name]"}}):_vm._e(),(entry.name === 'Employees._salutation')?_c('v-select',{key:entry.name,attrs:{"language":_vm.$i18n.locale,"items":[
              { text: _vm.$t('externalLogin.salutation.notSpecified'), value: 0 },
              { text: _vm.$t('externalLogin.salutation.female'), value: 1 },
              { text: _vm.$t('externalLogin.salutation.male'), value: 2 }
            ],"label":_vm.$t('externalLogin.field.salutation') +
              (entry.required ? ' *' : ''),"item-text":"text","item-value":"value","rules":entry.required ? _vm.requiredRule : []},model:{value:(_vm.additionalFieldData[entry.name]),callback:function ($$v) {_vm.$set(_vm.additionalFieldData, entry.name, $$v)},expression:"additionalFieldData[entry.name]"}}):_vm._e(),(entry.name === 'tbl_HCM_Mitarbeiter.gender')?_c('v-select',{key:entry.name,attrs:{"language":_vm.$i18n.locale,"items":[
              { text: _vm.$t('externalLogin.gender.notSpecified'), value: -1 },
              { text: _vm.$t('externalLogin.gender.female'), value: 0 },
              { text: _vm.$t('externalLogin.gender.male'), value: 1 },
              { text: _vm.$t('externalLogin.gender.divers'), value: 2 }
            ],"label":_vm.$t('externalLogin.field.gender') + (entry.required ? ' *' : ''),"item-text":"text","item-value":"value","rules":entry.required ? _vm.requiredRule : []},model:{value:(_vm.additionalFieldData[entry.name]),callback:function ($$v) {_vm.$set(_vm.additionalFieldData, entry.name, $$v)},expression:"additionalFieldData[entry.name]"}}):_vm._e()]}),_c('v-card-actions',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":!_vm.validAdditionalInfo || _vm.loginInProgress,"loading":_vm.loginInProgress},on:{"click":_vm.nextPage}},[_vm._v(" "+_vm._s(_vm.$t('externalLogin.buttonLabel.login'))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","text":"","color":"text","disabled":_vm.loginInProgress},on:{"click":_vm.lastPage}},[_vm._v(" "+_vm._s(_vm.$t('externalLogin.buttonLabel.back'))+" ")])],1)],1)],1)],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }